package uk.co.comsci.web.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.toMinWidthQuery
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import org.jetbrains.compose.web.css.*
import uk.co.comsci.web.theme.md_theme_dark_outlineVariant
import uk.co.comsci.web.toSitePalette

val MarkdownStyle = CssStyle {
    // The following rules apply to all descendant elements, indicated by the leading space.
    // When you use `cssRule`, the name of this style is prefixed in front of it.
    // See also: https://developer.mozilla.org/en-US/docs/Web/CSS/Descendant_combinator

    cssRule( " h1"){
        Modifier
            .color(colorMode.toSitePalette().brand.primaryPale)
            .fontSize(2.0.cssRem)
            .fontWeight(400)
            .margin(bottom = 2.5.cssRem)
            .lineHeight(1.2) //1.5x doesn't look as good on very large text
    }

    cssRule(Breakpoint.MD.toMinWidthQuery()," h1") {
        Modifier
            .fontSize(2.5.cssRem)
    }

    cssRule(" h2") {
        Modifier
            .color(colorMode.toSitePalette().brand.primaryPale)
            .fontSize(1.75.cssRem)
            .fontWeight(300)
            .margin(topBottom = 2.cssRem)
    }

    cssRule(Breakpoint.MD.toMinWidthQuery()," h2") {
        Modifier
            .fontSize(2.2.cssRem)
    }

    cssRule(" h3") {
        Modifier
            .fontSize(1.6.cssRem)
            .fontWeight(300)
            .margin(topBottom = 1.5.cssRem)
    }

    cssRule(Breakpoint.MD.toMinWidthQuery()," h3") {
        Modifier
            .fontSize(2.1.cssRem)
    }

    cssRule(" h4") {
        Modifier
            .fontSize(1.cssRem)
            .fontWeight(FontWeight.Bolder)
            .margin(top = 1.cssRem, bottom = 0.5.cssRem)
    }

    cssRule(" p") {
        Modifier
            .fontSize(1.2.cssRem)
            .margin(bottom = 0.8.cssRem)
    }

    cssRule(Breakpoint.MD.toMinWidthQuery()," p") {
        Modifier
            .fontSize(1.5.cssRem)
    }

    cssRule(" hr") {
        Modifier.border(width = 2.px, style = LineStyle.Solid, color = colorMode.toSitePalette().brand.accent)
    }


    cssRule(" ul") {
        Modifier.fillMaxWidth().overflowWrap(OverflowWrap.BreakWord)
    }

    cssRule(" li,ol,ul") {
        Modifier
            .fontSize(1.2.cssRem)
            .margin(bottom = 0.75.cssRem)
    }

    cssRule(Breakpoint.MD.toMinWidthQuery()," li,ol,ul") {
        Modifier
            .fontSize(1.5.cssRem)
    }


    cssRule(" code") {
        Modifier
            .color(colorMode.toPalette().color.toRgb().copyf(alpha = 0.8f))
            .fontWeight(FontWeight.Bolder)
    }

    cssRule(" pre") {
        Modifier
            .margin(top = 0.5.cssRem, bottom = 2.cssRem)
            .fillMaxWidth()
    }
    cssRule(" pre > code") {
        Modifier
            .display(DisplayStyle.Block)
            .fillMaxWidth()
            .backgroundColor(colorMode.toSitePalette().nearBackground)
            .border(1.px, LineStyle.Solid, colorMode.toPalette().color)
            .borderRadius(0.25.cssRem)
            .padding(0.5.cssRem)
            .fontSize(1.cssRem)
            .overflow { x(Overflow.Auto) }
    }

    cssRule(" img") {
        Modifier
            .display(DisplayStyle.Block)
            .fillMaxWidth()
            .backgroundColor(colorMode.toSitePalette().nearBackground)
            .border(2.px, LineStyle.Solid, md_theme_dark_outlineVariant)
            .borderRadius(0.25.cssRem)
            .padding(0.5.cssRem)
            .fontSize(1.cssRem)
            .overflow { x(Overflow.Auto) }
    }

    cssRule(" .pc30-width") {
        Modifier
            .width(30.percent)
    }

    cssRule(" .align-centre") {
        Modifier
            .alignSelf(AlignSelf.Center)
    }

    cssRule(" .extra-narrow-port") {
        Modifier
            .width(50.percent)
    }
    cssRule(Breakpoint.MD.toMinWidthQuery()," .extra-narrow-port") {
        Modifier
            .width(25.percent)
    }
    cssRule(" .narrow-port") {
        Modifier
            .width(75.percent)
    }
    cssRule(Breakpoint.MD.toMinWidthQuery()," .narrow-port") {
        Modifier
            .width(40.percent)
    }
}

@Composable
fun MarkdownLayout(title: String, desc:String, content: @Composable () -> Unit) {
    PageLayout(title, desc) {
        Column(MarkdownStyle.toModifier().fillMaxSize(), horizontalAlignment = Alignment.Start) {
            content()
        }
    }
}
