package uk.co.comsci.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    uk.co.comsci.web.components.layouts.MarkdownLayout("Bespoke Software Development", "Details of custom bespoke software development") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("custom-software-development-service") }) {
            org.jetbrains.compose.web.dom.Text("Custom Software Development Service")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("app-development-android-desktop-server-or-embedded") }) {
            org.jetbrains.compose.web.dom.Text("App development: Android, Desktop, Server or Embedded")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Quality bespoke software for many platforms, ranging from embedded microcontroller firmware, single board computers, Android App development, Desktop utility packages through to multi-processor server solutions. Always ready to take on challenging requirements particularly for the science, research and engineering industries with a 30+ year track record of delivering quality software that exceeds expectations. ")
            com.varabyte.kobweb.compose.dom.GenericTag("br", null)
            com.varabyte.kobweb.compose.dom.GenericTag("br", null)
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/DavidRobb.jpg\" alt=\"David Robb\" class=\"extra-narrow-port\"")
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("The Principal Consultant is David Robb, a Cambridge Physics graduate with many years of software development experience and an expert level knowledge of object-oriented design and its implementation in Kotlin, Java and C/C++.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Areas of expertise include Data Acquisition, Analysis and Visualisation, Mathematical Modelling, Simulation, Digital Signal Processing, Android Apps, Embedded Realtime and Microcontroller firmware.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Based in Ringwood on the edge of the New Forest and serve clients primarily in Hampshire, Dorset and Wiltshire within an area roughly bounded by Bournemouth, Poole, Dorchester, Blandford, Salisbury, Winchester, Portsmouth and Southampton. More distant or international projects are also considered where most of the work can be performed remotely.")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        org.jetbrains.compose.web.dom.H1(attrs = { id("bespoke-software-development-to-meet-any-requirements") }) {
            org.jetbrains.compose.web.dom.Text("Bespoke Software Development to meet any Requirements")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Embedded microcontroller firmware development")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Embedded custom Linux solutions featuring boot redundancy and over the air updates.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Single board computer applications with integrated sensors and WiFi networking.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Android App development using Google best practices in Jetpack Compose and Kotlin.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Multiplatform Desktop PC package creation in kotlin, java or C++ as appropriate")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Unix workstation or multi-node multi-processor High Performance Computing (HPC) solutions")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("I can provide:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Complete turnkey solutions")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Specialist software development to complement an existing project or device.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Refactoring of legacy installations. FORTRAN understood here.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Testing services. White box, black box, code coverage etc.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("3rd party independent code reviews, validation and verification.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Contract Programming for hire. To supplement an existing project.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Please get in ")
            com.varabyte.kobweb.silk.components.navigation.Link("/contact") {
                org.jetbrains.compose.web.dom.Text("Contact")
            }
            org.jetbrains.compose.web.dom.Text(" to start a discussion.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("I have a network of contacts available for assistance with larger projects or draw in engineering and hardware expertise to supplement my skills.")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("/technical") {
                org.jetbrains.compose.web.dom.Text("Technical Details")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("/") {
                org.jetbrains.compose.web.dom.Text("Back")
            }
        }
    }
}
