package uk.co.comsci.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun TechnicalPage() {
    uk.co.comsci.web.components.layouts.MarkdownLayout("Custom Software Skills", "List of skills and concepts familiar to Comsci Ltd.") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("list-of-software-development-skills") }) {
            org.jetbrains.compose.web.dom.Text("List of Software Development Skills")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("See ")
            com.varabyte.kobweb.silk.components.navigation.Link("/examples") {
                org.jetbrains.compose.web.dom.Text("Example Projects")
            }
            org.jetbrains.compose.web.dom.Text(" for better context")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Degree in Physics from Cambridge")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("C/C++, Kotlin, Java, Object Oriented, UML, JavaScript, Fortran, Lua, assembler")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Linux, Windows, Yocto, Android, QNX, VXWorks, Solaris, OpenVMS")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("STL, Boost, Qt, OpenGL, Jetpack Compose, JavaFX, Swing, MFC,")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Agile, Scrums, Design Patterns, TDD, JUnit, Mockito, Log4Cpp, CppUnit")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("TCP/IP, Infiniband, ATM, RS232, WiFi, Bluetooth, BLE, USB, I2C, ZeroMQ, Protocol Buffers, gRPC.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Signal processing, mathematical modelling, numerical methods, simulation, graphics.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Visual Studio, VS Code, Android Studio, MPLab, Netbeans, Gradle, Maven, Git, Mercurial, SVN.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Gdb, Lint, Valgrind, CMake, Atlassian tools, Enterprise Architect, Trello, VTune, WireShark.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Basic French and German")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Familiar with oscilloscopes, logic analysers, freq generators, good mechanical ability")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("/") {
                org.jetbrains.compose.web.dom.Text("Home")
            }
        }
    }
}
