package uk.co.comsci.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun ExamplesPage() {
    uk.co.comsci.web.components.layouts.MarkdownLayout("Custom Software Projects by Comsci Ltd", "A selection of previous projects completed by Comsci Ltd.") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("some-previous-bespoke-software-projects") }) {
            org.jetbrains.compose.web.dom.Text("Some Previous Bespoke Software Projects")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("running-gait-skeletal-tracking") }) {
            org.jetbrains.compose.web.dom.Text("Running Gait Skeletal Tracking.  ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://runright.io/") {
                org.jetbrains.compose.web.dom.Text("RUNRIGHT")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/runright_app2.jpg\" alt=\"RunRight Setup\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Created a system that automatically measures the running gait of an athlete.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Gigabytes of data to results in seconds from multi-threaded C++ and asynchronous processes")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Superior accuracy achieved by using multiple cameras and proprietary skeletal tracking")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Custom Yocto Linux and WiFi direct provides robustness, OTA updates and remote support.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/capture_engine.jpg\" alt=\"Capture Engine Analysis\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Java diagnostic client provides ease of debugging and access to all raw metrics")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Displays a browsable 3D OpenGL world view of point cloud data")
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("running-gait-analysis-android-app") }) {
            org.jetbrains.compose.web.dom.Text("Running Gait Analysis Android App")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/runright_app0.jpg\" alt=\"RunRight App\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Android App runs on a tablet and connects to the running gait cameras via WiFi Direct")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Built using best practices with Kotlin, Jetpack compose, coroutines and asynchronous flows.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Low level canvas methods used to create some re-usable elegant animated custom dials.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/runright_app.jpg\" alt=\"Run Right App\"")
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("crane-simulator-mathematical-model") }) {
            org.jetbrains.compose.web.dom.Text("Crane Simulator Mathematical Model")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/RigShot1.jpg\" alt=\"Crane Simulator Graphics\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("C# model that provides a true to life simulation for training offshore crane operators.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Advanced physics (Inertia tensors, 3-D kinematics, PID control loops, ray tracing)")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Includes wind shear, shadows, load imbalance, rope collision, sling failure, boom flex, buoyancy, water resistance, tidal flow etc.")
                }
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/KraneSimPhysics1.jpg\" alt=\"Crane Simulator Physics Model\"")
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("hitech-gym-equipment") }) {
            org.jetbrains.compose.web.dom.Text("HiTech Gym Equipment")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/JumpPlates0.jpg\" alt=\"Force Plates Design\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Designed the proprietary electronics and firmware for wireless force plates used to train and condition professional athletes.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("BeagleBone SBC runs custom yocto Linux for a fast boot and ease of updates.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("WiFi direct used to interface to an Android controller App.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/JumpPlates1.jpg\" alt=\"Jump plates hardware\"")
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("visualisation-of-digital-acoustic-sensing-data") }) {
            org.jetbrains.compose.web.dom.Text("Visualisation of Digital Acoustic Sensing data")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Provided contract programing for an Acoustic Sensing App in the Oil Field Services sector")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Java and Agile methods employed to enhance various DSP modules in the processing chain (Decimation, IIR Filters, FFT, de-striping etc).")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Ported and enhanced a Machine Learning model for tracking trains from Python Keras TensorFlow to Java using the DeepLearning4J library.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Transcribed various algorithms from Matlab to Java to boost performance by several times.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("3rd-party-code-reviews-for-medical-instrumentation-firmware") }) {
            org.jetbrains.compose.web.dom.Text("3rd Party Code Reviews for Medical Instrumentation Firmware")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/CodeReview1.jpg\" alt=\"code review hardware\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Provide independent code reviews for medical instrument suppliers")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Early identification of issues avoids embarrassing and expensive mistakes reaching production.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("phased-array-radar-vector-math-library") }) {
            org.jetbrains.compose.web.dom.Text("Phased Array Radar Vector Math Library")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/ShipRadar1.jpg\" alt=\"Ship Radar\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Helped design and implement the architecture for a phased array radar DSP.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Beowulf cluster of COTS multi-core Linux PCs connected via InfiniBand.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Design goals of maximum data throughput with minimal latency where achieved using vectorisation and CPU cache considerations.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("instrumentation-radar-analysis-software") }) {
            org.jetbrains.compose.web.dom.Text("Instrumentation Radar analysis software")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/MprRadar1.jpg\" alt=\"measurement radar\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Data visualisation of radar returns using OpenGL")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Multifunction I/O to interface A/D cards, optical trackers, weather stations, GPS clock, video titling and recording.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("making-tax-digital-vat-desktop-app") }) {
            org.jetbrains.compose.web.dom.Text("Making Tax Digital VAT Desktop App")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/mtd/100PcVatFreeBridge1.jpg\" alt=\"Making Tax Digital VAT\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Author of the free ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://comsci.co.uk/100PcVatFreeBridge.html") {
                    org.jetbrains.compose.web.dom.Text("100PcVatFreeBridge")
                }
                org.jetbrains.compose.web.dom.Text(" Making Tax Digital for VAT desktop App.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("A free forever, HMRC recognised, multi-platform App written using JavaFx.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Windows, MacOs or Linux to read Excel or CSV data files to make MTD VAT returns.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("ftp-camera-server-and-surveillance-camera-app") }) {
            org.jetbrains.compose.web.dom.Text("FTP Camera server and Surveillance Camera App")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/") {
                    org.jetbrains.compose.web.dom.Text("CamaC")
                }
                org.jetbrains.compose.web.dom.Text(", an FTP service for IP cameras with a Browser interface")
            }
            org.jetbrains.compose.web.dom.Li {
                com.varabyte.kobweb.silk.components.navigation.Link("https://app.comsci.co.uk/camcam_about.html") {
                    org.jetbrains.compose.web.dom.Text("CamCam")
                }
                org.jetbrains.compose.web.dom.Text(", a CCTV Camera Android App that uses AI to identify objects and trigger video recordings.")
            }
            org.jetbrains.compose.web.dom.Li {
                com.varabyte.kobweb.silk.components.navigation.Link("https://play.google.com/store/apps/details?id=uk.co.comsci.app.camacand&pcampaignid=web_share") {
                    org.jetbrains.compose.web.dom.Text("CamVue")
                }
                org.jetbrains.compose.web.dom.Text(" Android App for reviewing recorded images")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("timing-system-for-5k-10k-fun-runs") }) {
            org.jetbrains.compose.web.dom.Text("Timing system for 5k/10k fun runs")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/RabbitRun1.jpg\" alt=\"Run Timing table\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Timing system for the local scouts to time the runners participating in a 5k/10k fun run.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Runners are clicked in as they cross the line and results displayed on-line in real time.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("android-firework-display-synchronisation-app") }) {
            org.jetbrains.compose.web.dom.Text("Android Firework Display Synchronisation App")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/lightemup2.jpg\" class=\"narrow-port\" alt=\"Fireworks App\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Allows a firework display coordinator to synchronise and rate fireqorks in a display")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("datalink-link-22-algorithms") }) {
            org.jetbrains.compose.web.dom.Text("DataLink Link 22 Algorithms")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Added Link22 capabilities (in C++) to an existing legacy DataLink Processor (in Ada).")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Responsibile for the particularly challenging areas of the Electronic Warfare Surveillance and implementing the Multi-Link specific requirements.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("infra-red-model-code-review") }) {
            org.jetbrains.compose.web.dom.Text("Infra Red model code review")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/IrModel0.jpg\" alt=\"Infra Red Model\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Reviewed a large Fortran Infra red model for estimating the radiance of objects in varying atmospheric scenarios and made a number of recommendations for code readability and performance improvements.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("mxf-video-packaging-desktop-app") }) {
            org.jetbrains.compose.web.dom.Text("MXF Video Packaging Desktop App")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Created a tool using Java, RMI and JINI that allows users to manage the encoding / decoding and playback of MXF video files.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("laser-detector-proof-of-concept") }) {
            org.jetbrains.compose.web.dom.Text("Laser Detector Proof of concept")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Remember Borland Turbo C++? This was used to interface two sensor arrays to create a pattern matching laser detector demonstrator running on MS-Dos.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("hf-radio-signal-visualisation") }) {
            org.jetbrains.compose.web.dom.Text("HF Radio Signal Visualisation")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/hfmodel.png\" alt=\"HF Radio Model\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Enhanced several programs used in the calculation of radiation patterns from HF antennae using IRIX, Motif / X-Windows and OpenGL")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("3g-umts-node-b-test-harness") }) {
            org.jetbrains.compose.web.dom.Text("3G UMTS Node B Test Harness")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Used UML and C++ to design and implement a generic, script driven test harness for sending ASN.1 encoded packets through the system")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("terabit-network-router-simulator") }) {
            org.jetbrains.compose.web.dom.Text("Terabit Network Router Simulator")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Modelled packet flow under various conditions for a 128 LIC Internet Router. Improved the throughput 6-fold and designed a novel unicast / multicast fair scheduling algorithm.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("nokia-phone-settings-transfer-box") }) {
            org.jetbrains.compose.web.dom.Text("Nokia Phone Settings Transfer Box")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/NokiaMobiles1.jpg\" alt=\"Nokia Mobiles\"")
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("In the early days, before smartphones and Google backup etc. Copying your settings to a new phone was a daunting process. This transfer box, developed for Nokia, used RS-232 to copy settings from a host to a target phone.")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H2(attrs = { id("this-website") }) {
            org.jetbrains.compose.web.dom.Text("This website 😄")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("I created this site using ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/varabyte/kobweb") {
                org.jetbrains.compose.web.dom.Text("kobweb")
            }
            org.jetbrains.compose.web.dom.Text(", an opinionated Kotlin framework for creating websites and web apps, built on top of ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/JetBrains/compose-multiplatform#compose-html") {
                org.jetbrains.compose.web.dom.Text("Compose HTML")
            }
            org.jetbrains.compose.web.dom.Text(".")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Many thanks to ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://ui-rocket.com") {
                org.jetbrains.compose.web.dom.Text("UI Rocket")
            }
            org.jetbrains.compose.web.dom.Text(" for making the basic kobweb template freely available and to ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://www.phoenixredwolf.com/") {
                org.jetbrains.compose.web.dom.Text("PhoenixRedwolf Digital Services")
            }
            org.jetbrains.compose.web.dom.Text(" for some of the layout ideas")
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("/") {
                org.jetbrains.compose.web.dom.Text("Home")
            }
        }
    }
}
