import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { uk.co.comsci.web.pages.HomePage() }
        ctx.router.register("/100PcVatFreeBridge") { uk.co.comsci.web.pages.PcVatFreeBridgePage() }
        ctx.router.register("/about") { uk.co.comsci.web.pages.AboutPage() }
        ctx.router.register("/contact") { uk.co.comsci.web.pages.Contact() }
        ctx.router.register("/examples") { uk.co.comsci.web.pages.ExamplesPage() }
        ctx.router.register("/how-much") { uk.co.comsci.web.pages.HowMuchPage() }
        ctx.router.register("/malawi") { uk.co.comsci.web.pages.MalawiPage() }
        ctx.router.register("/opensource") { uk.co.comsci.web.pages.OpensourcePage() }
        ctx.router.register("/services") { uk.co.comsci.web.pages.ServicesPage() }
        ctx.router.register("/technical") { uk.co.comsci.web.pages.TechnicalPage() }
        ctx.router.register("/why-comsci") { uk.co.comsci.web.pages.WhyComsciPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.DISALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("headline-text", uk.co.comsci.web.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text", uk.co.comsci.web.SubheadlineTextStyle)
        ctx.theme.registerStyle("markdown", uk.co.comsci.web.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("page-content",
                uk.co.comsci.web.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("footer", uk.co.comsci.web.components.sections.FooterStyle)
        ctx.theme.registerStyle("nav-header", uk.co.comsci.web.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("form-button", uk.co.comsci.web.pages.FormButtonStyle)
        ctx.theme.registerStyle("form-input", uk.co.comsci.web.pages.FormInputStyle)
        ctx.theme.registerStyle("form-labels", uk.co.comsci.web.pages.FormLabelsStyle)
        ctx.theme.registerStyle("form-check-label", uk.co.comsci.web.pages.FormCheckLabelStyle)
        ctx.theme.registerStyle("check-box", uk.co.comsci.web.pages.CheckBoxStyle)
        ctx.theme.registerStyle("contact-form", uk.co.comsci.web.pages.ContactFormStyle)
        ctx.theme.registerStyle("p-contact-form", uk.co.comsci.web.pages.PContactFormStyle)
        ctx.theme.registerStyle("hero-container", uk.co.comsci.web.pages.HeroContainerStyle)
        ctx.theme.registerStyle("link-base", uk.co.comsci.web.theme.LinkBaseStyle)
        ctx.theme.registerStyle("nav-item", uk.co.comsci.web.theme.NavItemStyle)
        ctx.theme.registerStyle("nav", uk.co.comsci.web.theme.NavStyle)
        ctx.theme.registerVariant("-circle", uk.co.comsci.web.CircleButtonVariant)
        ctx.theme.registerVariant("-uncolored", uk.co.comsci.web.UncoloredButtonVariant)
        ctx.theme.registerKeyframes("side-menu-slide-in",
                uk.co.comsci.web.components.sections.SideMenuSlideInAnim)
        ctx.theme.registerKeyframes("shake", uk.co.comsci.web.pages.Shake)
        uk.co.comsci.web.initColorMode(ctx)
        uk.co.comsci.web.initSiteStyles(ctx)
        uk.co.comsci.web.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "App"))
    renderComposable(rootElementId = "root") {
        uk.co.comsci.web.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
