package uk.co.comsci.web.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun WhyComsciPage() {
    uk.co.comsci.web.components.layouts.MarkdownLayout("Why Comsci is best for custom software development","Why choose Comsci for custom software development") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("why-choose-comsci-for-custom-software-development") }) {
            org.jetbrains.compose.web.dom.Text("Why Choose Comsci for Custom Software Development?")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Over 30 years of experience producing valuable software.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Attention to technical excellence and good design. Many repeat clients show that I'm trusted and valued.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("You get better value direct as no middleman fees to pay.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("You get an experienced scientist rather than a managed newbie.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("You choose the level of control and can talk direct to the developer.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("I'm prepared to take on tiny projects or tweak existing code.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Fixed price possible for small projects with well defined requirements")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Or be adaptable with an hourly rate allowing flexible requirements and judge / manage by ongoing results.")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Delivery of working software frequently (weeks c.f. months)")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("Aim for Simplicity: maximize the amount of work not done")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Don't take our word for it. Check Comsci's 'Excellent' rating on ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://uk.trustpilot.com/review/comsci.co.uk") {
                org.jetbrains.compose.web.dom.Text("TrustPilot")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("img", "src=\"images/trustpilot1.jpg\" alt=\"Trustpilot rating\" class=\"narrow-port\"")
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("/how-much") {
                org.jetbrains.compose.web.dom.Text("How much will my software project cost?")
            }
        }
        com.varabyte.kobweb.compose.dom.GenericTag("br")
        org.jetbrains.compose.web.dom.P {
            com.varabyte.kobweb.silk.components.navigation.Link("/") {
                org.jetbrains.compose.web.dom.Text("Back")
            }
        }
    }
}
