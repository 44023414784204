package uk.co.comsci.web.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.mdi.MdiHome
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.Span
import uk.co.comsci.web.components.widgets.IconButton
import uk.co.comsci.web.toSitePalette

val FooterStyle = CssStyle.base {
    Modifier
        .fontSize(1.cssRem)
        .backgroundColor(colorMode.toSitePalette().nearBackground)
        .padding(topBottom = 1.5.cssRem, leftRight = 10.percent)
}

@Composable
fun Footer(modifier: Modifier = Modifier) {
    Row(
        FooterStyle.toModifier().gap(1.5.cssRem).then(modifier),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {

        val breakpoint = rememberBreakpoint()
        val sitePalette = ColorMode.current.toSitePalette()
        val ctx = rememberPageContext()
        IconButton(onClick = { ctx.router.tryRoutingTo("/") }) {
            MdiHome()
        }

        val tAndCText = if (breakpoint >= Breakpoint.MD) {
            "Terms & Conditions"
        } else {
            "T&Cs"
        }
        val privacyText = if (breakpoint >= Breakpoint.MD) {
            "Privacy Policy"
        } else {
            "Priv."
        }

        val copyrightText = if (breakpoint >= Breakpoint.MD) {
            "© Comsci Ltd. All rights reserved."
        } else {
            "© Comsci"
        }

        SpanText(copyrightText)
        Link(
            "https://app.comsci.co.uk/doc/CSS-terms-and-conditions.pdf",
            tAndCText,
            Modifier.setVariable(ColorVar, sitePalette.brand.primaryPale),
            variant = UncoloredLinkVariant
        )
        Link(
            "https://app.comsci.co.uk/doc/CSS-privacy-policy.pdf",
            privacyText,
            Modifier.setVariable(ColorVar, sitePalette.brand.primaryPale),
            variant = UncoloredLinkVariant
        )
        if (breakpoint >= Breakpoint.MD) {
            Span {
                SpanText("Built with ")
                Link(
                    "https://github.com/varabyte/kobweb",
                    "Kobweb",
                    Modifier.setVariable(ColorVar, sitePalette.brand.primaryPale),
                    variant = UncoloredLinkVariant
                )
            }
        }
    }
}
