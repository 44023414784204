package uk.co.comsci.web.theme

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgba

internal fun ColorVal(value: Long): CSSColorValue {

    val a = 0xFF
    val r = (value shr 16) and 0xFF
    val g = (value shr 8) and 0xFF
    val b = value and 0xFF
    return rgba(r, g, b, a)
}

val md_theme_light_primary = ColorVal(0x2117E3)
val md_theme_light_onPrimary = ColorVal(0xFFFFFF)
val md_theme_light_primaryContainer = ColorVal(0xE1E0FF)
val md_theme_light_onPrimaryContainer = ColorVal(0x06006C)
val md_theme_light_secondary = ColorVal(0x006C4C)
val md_theme_light_onSecondary = ColorVal(0xFFFFFF)
val md_theme_light_secondaryContainer = ColorVal(0x78FAC3)
val md_theme_light_onSecondaryContainer = ColorVal(0x002114)
val md_theme_light_tertiary = ColorVal(0x6D5E00)
val md_theme_light_onTertiary = ColorVal(0xFFFFFF)
val md_theme_light_tertiaryContainer = ColorVal(0xFCE265)
val md_theme_light_onTertiaryContainer = ColorVal(0x211B00)
val md_theme_light_error = ColorVal(0xBA1A1A)
val md_theme_light_errorContainer = ColorVal(0xFFDAD6)
val md_theme_light_onError = ColorVal(0xFFFFFF)
val md_theme_light_onErrorContainer = ColorVal(0x410002)
val md_theme_light_background = ColorVal(0xFFFBFF)
val md_theme_light_onBackground = ColorVal(0x1C1B1F)
val md_theme_light_surface = ColorVal(0xFFFBFF)
val md_theme_light_onSurface = ColorVal(0x1C1B1F)
val md_theme_light_surfaceVariant = ColorVal(0xE4E1EC)
val md_theme_light_onSurfaceVariant = ColorVal(0x46464F)
val md_theme_light_outline = ColorVal(0x777680)
val md_theme_light_inverseOnSurface = ColorVal(0xF3EFF4)
val md_theme_light_inverseSurface = ColorVal(0x313034)
val md_theme_light_inversePrimary = ColorVal(0xC0C1FF)
val md_theme_light_shadow = ColorVal(0x000000)
val md_theme_light_surfaceTint = ColorVal(0x3E3EF7)
val md_theme_light_outlineVariant = ColorVal(0xC8C5D0)
val md_theme_light_scrim = ColorVal(0x000000)

val md_theme_dark_primary = ColorVal(0xC0C1FF)
val md_theme_dark_onPrimary = ColorVal(0x0E00AA)
val md_theme_dark_primaryContainer = ColorVal(0x008080)
val md_theme_dark_onPrimaryContainer = ColorVal(0xE1E0FF)
val md_theme_dark_secondary = ColorVal(0x59DDA9)
val md_theme_dark_onSecondary = ColorVal(0x003826)
val md_theme_dark_secondaryContainer = ColorVal(0x005138)
val md_theme_dark_onSecondaryContainer = ColorVal(0x78FAC3)
val md_theme_dark_tertiary = ColorVal(0xDFC64C)
val md_theme_dark_onTertiary = ColorVal(0x393000)
val md_theme_dark_tertiaryContainer = ColorVal(0x524600)
val md_theme_dark_onTertiaryContainer = ColorVal(0xFCE265)
val md_theme_dark_error = ColorVal(0xFFB4AB)
val md_theme_dark_errorContainer = ColorVal(0x93000A)
val md_theme_dark_onError = ColorVal(0x690005)
val md_theme_dark_onErrorContainer = ColorVal(0xFFDAD6)
val md_theme_dark_background = ColorVal(0x1C1B1F)
val md_theme_dark_onBackground = ColorVal(0xE5E1E6)
val md_theme_dark_surface = ColorVal(0x1C1B1F)
val md_theme_dark_onSurface = ColorVal(0xE5E1E6)
val md_theme_dark_surfaceVariant = ColorVal(0x46464F)
val md_theme_dark_onSurfaceVariant = ColorVal(0xC8C5D0)
val md_theme_dark_outline = ColorVal(0x918F9A)
val md_theme_dark_inverseOnSurface = ColorVal(0x1C1B1F)
val md_theme_dark_inverseSurface = ColorVal(0xE5E1E6)
val md_theme_dark_inversePrimary = ColorVal(0x3E3EF7)
val md_theme_dark_shadow = ColorVal(0x000000)
val md_theme_dark_surfaceTint = ColorVal(0xC0C1FF)
val md_theme_dark_outlineVariant = ColorVal(0x46464F)
val md_theme_dark_scrim = ColorVal(0x000000)

val facebook_blue = ColorVal(0x1877F2)
val linkedin_blue = ColorVal(0x0072B1)

val primGradStart = ColorVal(0x002117E3)
val primGradEnd = ColorVal(0x2117E3)
