package uk.co.comsci.web.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.*
import com.varabyte.kobweb.silk.style.selectors.*
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import uk.co.comsci.web.components.layouts.PageLayout
import uk.co.comsci.web.theme.*

@Page
@Composable
fun Contact() {
    val description =
        "Comsci Ltd provide bespoke software development and contract programming to the science industries. Contact details here."

    PageLayout("Contact", description) {
        H1 {
            Text("Contact")
        }
        Column(ContactFormStyle.toModifier()) {
            ContactForm()
        }

        P(
            attrs = Modifier
                .margin(topBottom = 20.px)
                .fontWeight(FontWeight.SemiBold)
                .toAttrs()
        ) {
            Text("Comsci Ltd.")
            Br()
            Text("80 Northfield Road")
            Br()
            Text("Ringwood, UK")
            Br()
            Text("BH24 1ST")
            Br()
            Text("+44 1425 477912")
            Br()
            Link(
                modifier = LinkBaseStyle.toModifier(),
                path = "mailto:info@cosci.co.uk",
                text = "info@comsci.co.uk"
            )
            Br()
            Br()
            Link(
                modifier = LinkBaseStyle.toModifier(),
                path = "/",
                text = "Comsci Ltd"
            )
        }
    }
}

@Composable
fun ContactForm() {

    Form(
//        action = "https://formspree.io/f/xbjnryaa",
//        action = "http://localhost:8080/ContactServer",
        action = "https://app.comsci.co.uk/ContactServer",
        attrs = ContactFormStyle.toModifier()
            .attrsModifier {
                attr("method", "POST")
                attr("autocomplete", "off")
            }
            .toAttrs()
    ) {
        Div {
            Label(
                forId = "email",
                attrs = FormLabelsStyle.toModifier()
                    .classNames("form-label")
                    .fontSize(FontSize.XLarge)
                    .fontWeight(FontWeight.SemiBold)
                    .toAttrs(),
                content = { Text("Email Address  ") }
            )
            Input(
                type = InputType.Email,
                attrs = FormInputStyle.toModifier()
                    .fillMaxWidth()
                    .id("email")
                    .classNames("form-control")
                    .fontSize(FontSize.XLarge)
                    .fontWeight(FontWeight.SemiBold)
                    .attrsModifier {
                        attr("required", "true")
                        attr("name", "email")
                    }
                    .toAttrs()
            )
        }

        P(
            attrs = PContactFormStyle.toModifier()
                .toAttrs(),
        ) {
            Text("How can I help?")
            Br()
        }
        SimpleGrid(
            modifier = Modifier
                .gap(8.px)
                .classNames("form-check"),
            numColumns = numColumns(1, md = 2)
        ) {
            CheckBoxItem("Scientific", "Scientific Programming")
            CheckBoxItem("Embedded", "Embedded Development")
            CheckBoxItem("Desktop", "Desktop App Development")
            CheckBoxItem("Android", "Android App Development")
            CheckBoxItem("Contract", "Contract C++/Java/Kotlin coding")
            CheckBoxItem("Review", "3rd Party Code Review")
            CheckBoxItem("Other", "Other")
        }
        Column(
            modifier = Modifier
                .padding(topBottom = 10.px)
                .fillMaxWidth()
        ) {
            Label(
                forId = "commentsbox",
                attrs = FormLabelsStyle.toModifier()
                    .classNames("form-check-label")
                    .toAttrs(),
                content = { Text("Additional Information (optional)") }
            )
            TextArea(
                attrs = FormInputStyle.toModifier()
                    .id("commentsbox")
                    .classNames("form-control")
                    .fillMaxWidth()
                    .height(150.px)
                    .attrsModifier {
                        attr("name", "comments")
                        attr("maxlength", "4096")
                    }
                    .toAttrs()
            )
        }
        Row(
            modifier = Modifier
                .padding(top = 10.px)
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceAround
        ) {
            Button(
                attrs = FormButtonStyle.toModifier()
                    .classNames("btn", "btn-primary")
                    .attrsModifier {
                        attr("type", "submit")
                    }
                    .toAttrs(),
                content = { Text("Submit") }
            )
            Button(
                attrs = FormButtonStyle.toModifier()
                    .classNames("btn")
                    .attrsModifier {
                        attr("type", "reset")
                    }
                    .toAttrs(),
                content = { Text("Reset") }
            )
        }
    }
}

@Composable
fun CheckBoxItem(name: String, title: String) {
    Row(verticalAlignment = Alignment.CenterVertically) {
        Input(
            type = InputType.Checkbox,
            attrs = CheckBoxStyle.toModifier()
                .classNames("form-check-input")
                .attrsModifier {
                    attr("value", name)
                    attr("name", name.lowercase())
                }
                .id("check$name")
                .toAttrs()
        )
        Label(
            forId = "check$name",
            attrs = FormCheckLabelStyle.toModifier()
                .classNames("form-check-label")
                .toAttrs(),
            content = { Text(title) }
        )
    }
}

val FormButtonStyle = CssStyle {
    base {
        Modifier
            .width(100.px)
            .height(40.px)
            .fontWeight(FontWeight.SemiBold)
            .color(if (colorMode == ColorMode.LIGHT) onPrimaryContainerLight else onPrimaryContainerDark)
            .background(if (colorMode == ColorMode.LIGHT) primaryContainerLight else primaryContainerDark)
            .border {
                color(if (colorMode == ColorMode.LIGHT) onPrimaryContainerLight else onPrimaryContainerDark)
            }
            .transition(CSSTransition("background", 500.ms))
    }
    Breakpoint.MD {
        Modifier
            .width(120.px)
            .fontSize(FontSize.XLarge)
    }

    hover {
        Modifier
            .color(if (colorMode == ColorMode.LIGHT) onSecondaryContainerLight else onSecondaryContainerDark)
            .background(if (colorMode == ColorMode.LIGHT) secondaryContainerLight else secondaryContainerDark)
            .boxShadow(5.px, 5.px, 10.px, color = Colors.Black)
            .border { width(0.px) }
    }
}

val FormInputStyle = CssStyle {
    focus {
        Modifier
            .border {
                width(2.px)
                color(primaryLight)
            }
            .boxShadow(0.px)
            .color(primaryLight)
    }
}

val FormLabelsStyle = CssStyle {
    base {
        Modifier
            .color(if (colorMode == ColorMode.LIGHT) Colors.Black else onPrimaryContainerDark)
            .padding(left = 5.px)
            .margin(top = 10.px)
            .textAlign(TextAlign.Start)
    }
}

val FormCheckLabelStyle = CssStyle.base() {
    Modifier
        .fillMaxWidth()
        .color(if (colorMode == ColorMode.LIGHT) Colors.Black else onPrimaryContainerDark)
        .padding(left = 8.px)
        .textAlign(TextAlign.Start)
}

val CheckBoxStyle = CssStyle {
    base {
        Modifier
            .border(
                width = 2.px,
                style = LineStyle.Solid,
                color = if (colorMode == ColorMode.LIGHT) onPrimaryContainerLight else onPrimaryContainerDark
            )
            .boxShadow(0.px)
    }
    checked {
        Modifier
            .border(width = 0.px)
            .boxShadow(0.px)
            .color(Colors.Black)
            .backgroundColor(onSecondaryContainerLight)
    }
}

val ContactFormStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .borderRadius(r = 10.px)
            .backgroundColor(if (colorMode == ColorMode.LIGHT) primaryContainerLight else primaryContainerDark)
            .padding(5.px)
    }
    Breakpoint.MD {
        Modifier
            .padding(20.px)
    }
}

val PContactFormStyle = CssStyle {
    base {
        Modifier
            .padding(top = 10.px)
            .fillMaxWidth()
            .textAlign(TextAlign.Center)
            .fontSize(FontSize.Larger)
            .fontWeight(FontWeight.SemiBold)
            .color(if (colorMode == ColorMode.LIGHT) Colors.Black else onPrimaryContainerDark)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(FontSize.XLarge)
    }
}
