package uk.co.comsci.web.components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.css.percent
import uk.co.comsci.web.components.sections.Footer
import uk.co.comsci.web.components.sections.NavHeader
import uk.co.comsci.web.theme.onSurfaceDark
import uk.co.comsci.web.theme.onSurfaceLight
import uk.co.comsci.web.theme.surfaceDark
import uk.co.comsci.web.theme.surfaceLight

val PageContentStyle = CssStyle {
    base { Modifier.fillMaxSize().padding(leftRight = 2.cssRem, top = 2.cssRem) }
    Breakpoint.MD { Modifier.maxWidth(70.cssRem) }
}

@Composable
fun PageLayout(
    title: String,
    description: String,
    content: @Composable () -> Unit
) {
    LaunchedEffect(title, description) {
        document.title = title
        document.querySelector("""meta[name="description"]""")!!.setAttribute("content", description)
    }
    Box(
        ref = ref {
            val script = document.createElement("script")
            script.asDynamic().src = "https://www.googletagmanager.com/gtag/js?id=G-ZJDTCNCJPN"
            it.appendChild(script)
            val scriptElement = document.createElement("script")
            scriptElement.innerHTML =
                "window.dataLayer = window.dataLayer || [];\nfunction gtag(){dataLayer.push(arguments);}\ngtag('js', new Date());\n\ngtag('config', 'G-ZJDTCNCJPN');"
            it.appendChild(scriptElement)
        }
    )
    Box(
        ref = ref {
            val scriptElement = document.createElement("script")
            scriptElement.innerHTML =
                "window.dataLayer = window.dataLayer || [];\nfunction gtag(){dataLayer.push(arguments);}\ngtag('js', new Date());\n\ngtag('config', 'G-ZJDTCNCJPN');"
            it.appendChild(scriptElement)
        }
    )
    val colorMode by ColorMode.currentState

    Box(
        Modifier
            .fillMaxWidth()
            .minHeight(100.percent)
            .backgroundColor(if (colorMode == ColorMode.LIGHT) surfaceLight else surfaceDark)
            .color(if (colorMode == ColorMode.LIGHT) onSurfaceLight else onSurfaceDark)

            // Create a box with two rows: the main content (fills as much space as it can) and the footer (which reserves
            // space at the bottom). "min-content" means the use the height of the row, which we use for the footer.
            // Since this box is set to *at least* 100%, the footer will always appear at least on the bottom but can be
            // pushed further down if the first row grows beyond the page.
            // Grids are powerful but have a bit of a learning curve. For more info, see:
            // https://css-tricks.com/snippets/css/complete-guide-grid/
            .gridTemplateRows { size(1.fr); size(minContent) },
        contentAlignment = Alignment.Center
    ) {
//        SvgCobweb(Modifier.gridRow(1).align(Alignment.TopStart))
        Column(
            // Isolate the content, because otherwise the absolute-positioned SVG above will render on top of it.
            // This is confusing but how browsers work. Read up on stacking contexts for more info.
            // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
            // Some people might have used z-index instead, but best practice is to avoid that if possible, because
            // as a site gets complex, Z-fighting can be a huge pain to track down.
            Modifier.fillMaxSize().gridRow(1),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            NavHeader()
            Column(
                PageContentStyle.toModifier(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }
        }
        // Associate the footer with the row that will get pushed off the bottom of the page if it can't fit.
        Footer(Modifier.fillMaxWidth().gridRow(2))
    }
}
