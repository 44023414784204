package uk.co.comsci.web.pages

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text
import uk.co.comsci.web.HeadlineTextStyle
import uk.co.comsci.web.SubheadlineTextStyle
import uk.co.comsci.web.components.layouts.PageLayout
import uk.co.comsci.web.toSitePalette

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle = CssStyle {
    base { Modifier.fillMaxWidth().gap(4.cssRem) }
    Breakpoint.MD { Modifier.margin { top(10.vh) } }
}

val Shake = Keyframes {
    val twist = 2.deg
    val slidey = (-10).px
    70.percent.invoke { Modifier.translate(0.px, 0.px).rotate(0.deg); }
    72.percent.invoke { Modifier.translate(0.px, slidey).rotate(twist); }
    74.percent.invoke { Modifier.translate(0.px, slidey).rotate(-twist); }
    76.percent.invoke { Modifier.translate(0.px, slidey).rotate(twist); }
    78.percent.invoke { Modifier.translate(0.px, 0.px).rotate(0.deg); }
}

@Page
@Composable
fun HomePage() {
    val description =
        "Comsci Ltd. Bespoke software development consultancy and contract programming for the science and research industries."
    PageLayout("Scientific Software Development Consultancy", description) {
        Column(HeroContainerStyle.toModifier()) {

            val ctx = rememberPageContext()
            val breakpoint = rememberBreakpoint()

            val sitePalette = ColorMode.current.toSitePalette()
            Row {
                Box {

                    Column(Modifier.gap(2.cssRem)) {
                        Div(HeadlineTextStyle.toAttrs()) {
                            SpanText(
                                "Comsci Ltd", Modifier.color(sitePalette.brand.light)
                            )
                        }
                        H1(HeadlineTextStyle.toAttrs()) {
                            SpanText(
                                "Custom Software Developer for hire",
                                Modifier
                                    .color(sitePalette.brand.accent)
                                    // Use a shadow so this light-colored word is more visible in light mode
                                    .textShadow(0.px, 0.px, blurRadius = 0.5.cssRem, color = Colors.Gray)
                            )
                        }

                        H2(SubheadlineTextStyle.toAttrs()) {
                            SpanText("Scientific Software Specialist", Modifier.color(sitePalette.brand.light))
                        }
                    }
                }

                Div(
                    Modifier
                        .displayIfAtLeast(Breakpoint.MD)
                        .toAttrs()
                ) {
                    Image(
                        "/images/MainPageDrawing_plain.png", "ComsciImage", Modifier
                            .height(24.cssRem)
                            .borderRadius(1.cssRem)
                            .display(DisplayStyle.Block)
                    )
                }
            }

            Div(
                SubheadlineTextStyle.toModifier()
                    .fillMaxWidth()
                    .borderRadius(10.px)
                    .backgroundColor(sitePalette.brand.primary).position(Position.Relative)
                    .padding(topBottom = 1.cssRem, leftRight = 1.cssRem)
                    .animation(
                        Shake.toAnimation(
                            duration = 5.s,
                            timingFunction = AnimationTimingFunction.Linear,
                            direction = AnimationDirection.Normal,
                            iterationCount = AnimationIterationCount.Infinite
                        )
                    )
                    .toAttrs()
            ) {
                SpanText(
                    "Available now for new projects in 2024!",
                    Modifier
                        .fillMaxWidth()
                        .color(sitePalette.brand.accent)
                        .textAlign(TextAlign.Center)
                        .onClick { ctx.router.tryRoutingTo("/contact") }
                )
            }

            if (breakpoint >= Breakpoint.MD) {
                Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceBetween) {
                    FPButton("About") { ctx.router.tryRoutingTo("/about") }
                    FPButton("Why Comsci?") { ctx.router.tryRoutingTo("/why-comsci") }
                    FPButton("MTD VAT Software") { ctx.router.tryRoutingTo("/100PcVatFreeBridge") }
                    FPButton("Portfolio") { ctx.router.tryRoutingTo("/examples") }
                    FPButton("Contact") { ctx.router.tryRoutingTo("/contact") }
                }
            } else {
                Column(Modifier.fillMaxWidth().gap(1.cssRem)) {
                    FPButton("About") { ctx.router.tryRoutingTo("/about") }
                    FPButton("Why Comsci?") { ctx.router.tryRoutingTo("/why-comsci") }
                    FPButton("MTD VAT Software") { ctx.router.tryRoutingTo("/100PcVatFreeBridge") }
                    FPButton("Portfolio") { ctx.router.tryRoutingTo("/examples") }
                    FPButton("Contact") { ctx.router.tryRoutingTo("/contact") }
                }
            }
        }
    }
}

@Composable
private fun FPButton(label: String, onClick: (evt: SyntheticMouseEvent) -> Unit) {
    Button(onClick = onClick, colorScheme = ColorSchemes.Teal) {
        Text(label)
    }
}