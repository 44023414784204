package uk.co.comsci.web.theme

import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient

// lightColorScheme
val primaryLight = md_theme_light_primary
val secondaryLight = md_theme_light_secondary
val tertiaryLight = md_theme_light_tertiary
val errorLight = md_theme_light_error
val backgroundLight = md_theme_light_background
val outlineLight = md_theme_light_outline
val onPrimaryLight = md_theme_light_onPrimary
val onSecondaryLight = md_theme_light_onSecondary
val onTertiaryLight = md_theme_light_onTertiary
val onErrorLight = md_theme_light_onError
val onBackgroundLight = md_theme_light_onBackground
val primaryContainerLight = md_theme_light_primaryContainer
val secondaryContainerLight = md_theme_light_secondaryContainer
val tertiaryContainerLight = md_theme_light_tertiaryContainer
val errorContainerLight = md_theme_light_errorContainer
val surfaceLight = md_theme_light_surface
val surfaceVariantLight = md_theme_light_surfaceVariant
val onPrimaryContainerLight = md_theme_light_onPrimaryContainer
val onSecondaryContainerLight = md_theme_light_onSecondaryContainer
val onTertiaryContainerLight = md_theme_light_onTertiaryContainer
val onErrorContainerLight = md_theme_light_onErrorContainer
val onSurfaceLight = md_theme_light_onSurface
val onSurfaceVariantLight = md_theme_light_onSurfaceVariant
val inverseOnSurfaceLight = md_theme_light_inverseOnSurface
val inverseSurfaceLight = md_theme_light_inverseSurface
val inversePrimaryLight = md_theme_light_inversePrimary
val shadowLight = md_theme_light_shadow
val surfaceTintLight = md_theme_light_surfaceTint
val outlineVariantLight = md_theme_light_outlineVariant
val scrimLight = md_theme_light_scrim

// Dark Color scheme
val primaryDark = md_theme_dark_primary
val secondaryDark = md_theme_dark_secondary
val tertiaryDark = md_theme_dark_tertiary
val errorDark = md_theme_dark_error
val backgroundDark = md_theme_dark_background
val outlineDark = md_theme_dark_outline
val onPrimaryDark = md_theme_dark_onPrimary
val onSecondaryDark = md_theme_dark_onSecondary
val onTertiaryDark = md_theme_dark_onTertiary
val onErrorDark = md_theme_dark_onError
val onBackgroundDark = md_theme_dark_onBackground
val primaryContainerDark = md_theme_dark_primaryContainer
val secondaryContainerDark = md_theme_dark_secondaryContainer
val tertiaryContainerDark = md_theme_dark_tertiaryContainer
val errorContainerDark = md_theme_dark_errorContainer
val surfaceDark = md_theme_dark_surface
val surfaceVariantDark = md_theme_dark_surfaceVariant
val onPrimaryContainerDark = md_theme_dark_onPrimaryContainer
val onSecondaryContainerDark = md_theme_dark_onSecondaryContainer
val onTertiaryContainerDark = md_theme_dark_onTertiaryContainer
val onErrorContainerDark = md_theme_dark_onErrorContainer
val onSurfaceDark = md_theme_dark_onSurface
val onSurfaceVariantDark = md_theme_dark_onSurfaceVariant
val inverseOnSurfaceDark = md_theme_dark_inverseOnSurface
val inverseSurfaceDark = md_theme_dark_inverseSurface
val inversePrimaryDark = md_theme_dark_inversePrimary
val shadowDark = md_theme_dark_shadow
val surfaceTintDark = md_theme_dark_surfaceTint
val outlineVariantDark = md_theme_dark_outlineVariant
val scrimDark = md_theme_dark_scrim

val primaryGradient = linearGradient(LinearGradient.Direction.ToBottomRight, primGradStart, primGradEnd)
//val secondaryGradient = linearGradient(LinearGradient.Direction.ToBottomRight, Color(0x00006c4c), Color(0xFF006c4c))

val facebookHover = facebook_blue
val linkedInHover = linkedin_blue

